import React from "react";
import "./index.scss";

function NavBar() {
	return (
		<div className="navbar__container">
			<div className=" navbar__nav-item  navbar__heading">Incogly</div>
		</div>
	);
}

export default NavBar;
